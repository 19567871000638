export const sectionStyle = {
    background: '#eee',
    padding: '20px',
    boxShadow: '0px 0px 11px 0px rgb(50 50 50 / 47%)',
    textAlign: 'center',
    marginBottom: '40px',
}
export const widgetWrapStyle = {
    // Left blank on purpose
    // Custom styles can go here
}
export const widgetTextStyle = {
    // Left blank on purpose
    // Custom styles can go here
}

export const widgetInputStyle = {
    backgroundColor: "#fff",
    border: "1px solid #CFD8DC",
    color: "#263238",
    fontFamily: "'HurmeGeoSans', sans-serif",
    fontSize: "1.6rem",
    fontWeight: "400",
    padding: "17px 12px 15px",
    lineHeight: "1.15",
    boxSizing: "border-box",
    margin: 0,
    height: "auto",
    verticalAlign: "inherit",
    width: "100%",
    textAlign: "center",
    borderRadius: "100px",
    marginBottom: "25px",
}

export const submitButton = {
    fontSize: "1.6rem",
    fontStyle: "'HurmeGeoSans', sans-serif",
    padding: "16px 32px 14px",
    position: "relative",
    textDecoration: "none",
    whiteSpace: "normal",
    cursor: "pointer",
    display: "block",
    width: "100%",
    background: '#00663e',
    margin: 0,
    height: "auto",
    verticalAlign: "inherit",
    border: "1px solid #fff",
    color: "#fff",
    textAlign: "center",
    borderRadius: "100px",
    marginBottom: "25px",
}

export const customBoxStyle = {
    fontFamily: "'HurmeGeoSans', sans-serif",
}

export const customBoxH1 = {
    fontSize: '23px',
    fontWeight: '500',
}

export const linkButton = {
    border: '2px solid #fff',
    margin: '10px',
    padding: '10px',
    borderRadius: '1000px',
    textDecoration: 'none',
    fontFamily: "'HurmeGeoSans', sans-serif",
    background: '#00663e',
    fontStyle: 'normal !important',
    outline: 'none',
    color: 'white',
}