import React from "react"

const articleStyle = {
    marginBottom: '0',
    boxSizing: 'inherit',
    display: 'block',
}

const headerStyle = {
    margin: '0 auto',
    boxSizing: 'inherit',
    display: 'block',
}

const h4Style = {
    color: '#0066cc',
    fontSize: '1.4rem',
    lineHeight: '1.5',
    marginBottom: '8px',
    marginTop: '8px',
}

const aStyle = {
    color: '#0066cc',
    textDecoration: 'underline',
    display: 'inline-block',
    transition: 'color 0.2s ease-in-out, background-color 0.2s ease-in-out',
    backgroundColor: 'transparent',
    letterSpacing: '0.2px',
}

export default function FeaturedPostLink({link, text}) {
    return (
        <article style={articleStyle}>
            <header style={headerStyle}>
                <h4 style={h4Style}>
                    <a style={aStyle} href={link}>{text}</a>
                </h4>
            </header>
        </article>
    )
}
