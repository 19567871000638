import React from "react"
import scentingGuideImage from '../../assets/scenting-guide-pdf-preview.png'
import {sectionStyle,
        widgetWrapStyle,
        widgetTextStyle,
        customBoxStyle,
        customBoxH1,
        linkButton} from './Styles'


const hrStyle = {
    marginTop: '0px',
    marginBottom: "30px",
    borderTop: "1px solid #cfd8dc00",
}

export default function WidgetDownload() {
    return (
        <section style={sectionStyle}>
            <div style={widgetWrapStyle}>
                <div style={widgetTextStyle}>
                    <div style={customBoxStyle}>
                        <img src={scentingGuideImage} alt="Essential oil scenting guide cover with various brown bottles and plants coming out of them" />
                        <br />
                        <br />
                        <h1 style={customBoxH1}>Custom Scenting Guide From Our Certified Aromatherapist</h1>
                        <a href="/aromatherapy-scenting-guide/" style={linkButton}>Get The Guide</a>
                        <hr style={hrStyle}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
