import React from "react"
import styled from "styled-components"


const divStyle = {
    margin: '0 auto',
    position: 'relative',
    right: '0px',
    maxWidth: '1050px',
}

const Aside = styled.aside`
    float: right;
    font-size: 1.6rem;
    line-height: 1.5;
    width: 263px;

    @media only screen and (max-width: 1250px) {
        padding-top: 0px !important;
        float: none;
        margin: 0 auto;
        max-width: 600px;
    }
`

export default function Sidebar({children}) {
    return (
        <div style={divStyle}>
            <Aside>
                {/* Widget components go here */}
                {children}
            </Aside>
        </div>
        
    )
}

