import React, { useState, useEffect } from "react"
import SearchResults from "../components/search/search-results"
import Layout from "../components/layout/Layout"
import Seo from "../components/layout/seo"
import {
  SiteInner,
  ResultSidebarWrap,
  ResultsContainer,
} from "../components/archive/Styles"
import Hotbar from "../components/archive/Hotbar"
import Sidebar from "../components/sidebar/Sidebar"
import WidgetDownload from "../components/sidebar/WidgetDownload"
import WidgetFeaturedPostList from "../components/sidebar/WidgetFeaturedPostList"

const SearchPage = ({ location }) => {

  const [searchTerm, setSearchTerm] = useState("")

  // A useEffect hook is needed to ensure that window.location.search is run
  // in the browser and not during build time.
  useEffect(() => {
    const query = window.location.search;
    return setSearchTerm(query.substring(3, query.length))
  }, [])


  return (
    <Layout location={location}>
      <Seo title="Search Results" />
      {/* We're replacing '+' with white space in the hotbar */}
      <Hotbar title={`Search results for: ${searchTerm.replace(/\+/g,' ')}`} />
      <SiteInner>
        <ResultSidebarWrap>
          <ResultsContainer>
            <SearchResults searchTerm={searchTerm} />
          </ResultsContainer>
          <Sidebar>
            <div style={{ height: "18px" }}>{/* Empty Div */}</div>
            <WidgetDownload />
            <WidgetFeaturedPostList />
          </Sidebar>
        </ResultSidebarWrap>
      </SiteInner>
    </Layout>
  )
}

export default SearchPage
