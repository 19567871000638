import React from "react"
import Result from "./result"


export default function ResultList({ posts }) {
  return (
    <>
      {posts.map(post => {
        return <Result data={post.post} key={post.post.uri}/>
      })}
    </>
  )
}
