import styled from "styled-components";

export const SBFeaturedPostSection = styled.section`
    top: auto;
    margin-bottom: 40px;
    border-left: 2px solid #CFD8DC;
    padding: 5px 20px;
    position: absolute;

    @media only screen and (max-width: 1250px) {
        position: static;
    }
`