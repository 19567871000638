import React from "react"
import FeaturedPostLink from './FeaturedPostLink'
import { SBFeaturedPostSection } from '../styles/SBFeaturedPostSection.styled'

const widgetWrapStyle = {
    // Left blank on purpose
    // Custom styles can go here
}
const h3Style = {
    fontSize: '1.6rem',
    marginBottom: '16px',
    fontWeight: '600',
    letterSpacing: '-0.5px',
}


export default function WidgetFeaturedPostList() {
    return (
        <SBFeaturedPostSection>
            <div style={widgetWrapStyle}>
                <h3 style={h3Style}>You might also like:</h3>
                <FeaturedPostLink link="/diy-basics-making-cosmetics-home" text="DIY Basics: How To Make Cosmetics at Home"/>
                <FeaturedPostLink link="/pricing-your-natural-and-organic-skin-care-products" text="How To Price Your Natural and Organic Skin Care Products"/>
                <FeaturedPostLink link="/skincare-packaging-basics-choosing-right-material-product" text="Skincare Packaging Basics: Choosing the Right Material for your Product"/>
                <FeaturedPostLink link="/what-are-emulsifiers-and-solubilizers" text="What are Emulsifiers and Solubilizers?"/>
                <FeaturedPostLink link="/carrier-oils-overview-substitutions" text="Carrier Oils – Overview and Substitution Guide"/>
            </div>
        </SBFeaturedPostSection>
    )
}
