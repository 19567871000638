import React, {useState, useEffect} from "react"
import '../pagination/styles.scss'
import ResultList from "./result-list"
import { useQuery, gql } from "@apollo/client"
import ReactPaginate from 'react-paginate'




const GET_RESULTS = gql`
query ($searchTerm: String, $offset: Int, $size: Int) {
  posts(
    where: {search: $searchTerm, offsetPagination: {offset: $offset, size: $size}}
  ) {
    pageInfo {
      offsetPagination {
        total
        hasMore
        hasPrevious
      }
    }
    edges {
      post: node {
        uri
        date
        title
        excerpt
        author {
          node {
            name
            uri
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl(size: MEDIUM)
          }
        }
      }
    }
  }
}
`



const SearchResults = ({ searchTerm,  }) => {

  const [offset, setOffset] = useState(0)

  // Size is the number of results to display at one time
  // size is used in apollo's graphql query
  const size = 10;

  // Scroll to top when the state changes,
  // aka when user clicks on pagination
  useEffect(() => {
    window.scrollTo({ behavior: 'auto', top: '0px' });
  }, [offset]);


  // Get first 10 results based on searchTerm
  const { data, loading, error } = useQuery(GET_RESULTS, {
    variables: { searchTerm, offset, size }
  })

  if (loading) return <p>Searching posts for {searchTerm.replace(/\+/g,' ')}...</p>
  if (error) return <p>Error - {error.message}</p>


  const pageInfo = data.posts.pageInfo.offsetPagination
  const totalPages = Math.ceil(pageInfo.total / size)

  const handlePageClick = (e) => {
    const selectedPage = e.selected
    return setOffset(selectedPage * 10)
  }

  return (
      <>
      <ResultList posts={data.posts.edges} />
      {/** 
         * Controls pagination links
         * See https://github.com/AdeleD/react-paginate#readme
         */}
        <ReactPaginate
          breakLabel=""
          disableInitialCallback="true"
          forcePage={offset / 10}
          marginPagesDisplayed={0}
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={9}
          pageCount={totalPages}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName={"container responsive"}
          activeClassName={"active"}
        />

        {/* Empty div so pagination doesn't get cut off */}
        <div style={{height: '20px', width: '100%'}}></div>
      </>
  )
}



export default SearchResults